import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';
import { Visibility } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import Layout from '../components/layout';
import Navigator from '../components/navigator';
import AuthenticatedPage from '../components/authenticated-page';
import RequireRole from '../components/require-role';
import {
  navigate,
  getVaspCode,
  checkJWTExpired,
  getToken,
  getRoleId,
  genNavigatePath,
} from '../utils/utils';

import { RoleId, OperationStatus, OperationStatusContents } from '../constants';
import { GET_VASP, RESEND } from '../crud';

function VaspList() {
  const token = getToken();
  if (!token || checkJWTExpired(token)) {
    // push back to home if not logged in or token expired
    navigate('/');
  }

  const vaspRoleId = getRoleId(token);

  const [vaspData, setVaspData] = useState([]);

  const [resend] = useMutation(RESEND);

  const [getVasp] = useLazyQuery(GET_VASP, {
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      const vasps = data.vasps.map((v) => {
        const vaspCode = getVaspCode(v);
        const tokenExpiresIn = v.token_expires_in.split(' ')[0];
        const vaspStatusContent = OperationStatusContents[v.vasp_status].replace('\n', ' ');
        return {
          ...v,
          vasp_code: vaspCode,
          token_expires_in: tokenExpiresIn,
          vasp_status_content: vaspStatusContent,
        };
      });
      setVaspData(vasps);
    },
    onError: (error) => {
      // TODO: use dialog
      alert(`getVasp error : ${JSON.stringify(error)}`);
    },
    fetchPolicy: 'no-cache',
  });

  const resendRegistrationMail = async (rowData) => {
    try {
      await resend({
        variables: {
          id: rowData.id,
        },
        context: {
          headers: {
            authorization: token,
          },
        },
      });
      getVasp();
      // TODO: use dialog
      alert(`resend ${rowData.vasp_name} registeration mail success!`);
    } catch (error) {
      // TODO: use dialog
      alert(`resend ${rowData.vasp_name} registeration mail fail!`);
    }
  };

  useEffect(() => {
    if (token && !checkJWTExpired(token)) {
      getVasp();
    }
  }, []);

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator breadcrumbTexts={['Home', 'Vasp List']} breadcrumbLinks={['/', '/vasp-list']} />
        <div style={{ maxWidth: '100%' }}>
          <br />
          <RequireRole roleIds={[RoleId.ACCOUNT_MANAGER, RoleId.ADMIN]}>
            <Button variant="outlined" href={genNavigatePath('/create-vasp')} id="create_vasp">
              Create VASP
            </Button>
          </RequireRole>
          <br />
          <MaterialTable
            components={{
              Row: (props) => {
                const index = _.get(props, ['index'], '');
                const id = `vasp_list_row-${index}`;
                return <MTableBodyRow id={id} {...props} />;
              },
            }}
            title=""
            columns={[
              {
                title: 'Name',
                field: 'vasp_name',
              },
              { title: 'Email', field: 'vasp_email' },
              { title: 'Status', field: 'vasp_status_content' },
            ]}
            data={vaspData}
            actions={[
              {
                icon: Visibility,
                onClick: (__, rowData) => navigate('/review-created-vasp', { state: { rowData } }),
                hidden: vaspRoleId !== RoleId.ACCOUNT_MANAGER && vaspRoleId !== RoleId.ADMIN,
              },
              (row) => ({
                icon: 'send',
                tooltip: 'resend registration mail to vasp',
                onClick: (__, data) => resendRegistrationMail(data),
                hidden: row.vasp_status !== OperationStatus.Expired,
              }),
            ]}
          />
        </div>
      </AuthenticatedPage>
    </Layout>
  );
}

export default VaspList;
